import React, { Fragment } from 'react';

import type { TrainingPeriod } from 'models';
import type { RouteComponentProps } from 'react-router-dom';

import { useAppDispatch, useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { navigate } from 'helpers/navigation';
import {
  pathToTrainingSessionDetails,
  pathToTrainingSessionIndex,
} from 'helpers/paths';

import { post } from 'redux/actions/api';

import { Button, ContentContainer, Icon } from 'components';

import ExportModal from 'scenes/admin/components/ExportModal';
import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';

import PageHeader from '../../components/PageHeader';
import SessionList from './SessionList';
import SessionStats from './SessionStats';

type RouterProps = RouteComponentProps<{ periodSlug: string }>;

const Sessions = ({ match }: RouterProps) => {
  const organization = useOrganization();
  const { periodSlug } = match.params;
  const [exportModalIsActive, setExportModalIsActive] = React.useState(false);
  const dispatch = useAppDispatch();

  invariant(periodSlug, 'Training period must be defined.');

  const handleExport = async () => {
    await dispatch(post(`training/periods/${periodSlug}/sessions/export`));
    setExportModalIsActive(true);
  };

  const createNewSession = async () => {
    const response = await dispatch(
      post(`training/sessions`, { periodSlug: periodSlug })
    );
    const session = response.response.body.data;
    navigate(pathToTrainingSessionDetails(session.id));
  };

  const newTrainingSessionFieldsEnabled = organization.featureFlags.includes(
    'newTrainingSessionFields'
  );

  return (
    <Fragment>
      <PageHeader periodSlug={periodSlug} />
      <ContentContainer>
        <div className="flex my-4 justify-between">
          <div className="w-40">
            <TrainingPeriodPicker
              renderingStyle="popover"
              currentPeriodSlug={periodSlug}
              onChange={(period: TrainingPeriod) =>
                navigate(pathToTrainingSessionIndex(period.slug))
              }
            />
          </div>
          <div>
            {newTrainingSessionFieldsEnabled && (
              <Button
                additionalClassName="mr-2"
                color="secondary"
                onClick={handleExport}
              >
                {__('Export sessions')}
              </Button>
            )}
            <Button color="primary" onClick={() => createNewSession()}>
              <Icon name="add" additionalClassName="mr-1" />
              {__('Create a training session')}
            </Button>
          </div>
        </div>
        <div className="my-4">
          <SessionStats periodSlug={periodSlug} />
        </div>
        <SessionList periodSlug={periodSlug} paginationType="url" />
      </ContentContainer>
      {exportModalIsActive && (
        <ExportModal isActive onClose={() => setExportModalIsActive(false)} />
      )}
    </Fragment>
  );
};

export default Sessions;
