import React, { Fragment } from 'react';

import type { RouteComponentProps } from 'react-router-dom';

import { TrainingPeriod } from 'models/TrainingPeriod';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { navigate } from 'helpers/navigation';
import { pathToTrainingPeriodRequests } from 'helpers/paths';

import { post } from 'redux/actions/api';

import { Button, ContentContainer, Icon } from 'components';

import ExportModal from 'scenes/admin/components/ExportModal';
import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';
import TrainingRequestModal from 'scenes/components/TrainingRequestModal';

import PageHeader from '../components/PageHeader';
import RequestList from './RequestList';
import RequestStats from './RequestStats';
import { refetchTrainingPeriodStats } from './RequestStats';

type Props = RouteComponentProps<{ periodSlug: string }>;

function PeriodRequests({ match }: Props) {
  const [createModalIsActive, setCreateModalIsActive] = React.useState(false);
  const [exportModalIsActive, setExportModalIsActive] = React.useState(false);
  const { periodSlug } = match.params;

  const dispatch = useAppDispatch();

  const refreshStats = () => dispatch(refetchTrainingPeriodStats(periodSlug));

  invariant(periodSlug, 'Training period must be defined.');

  const handleExport = async () => {
    await dispatch(post(`training/periods/${periodSlug}/requests/export`));

    setExportModalIsActive(true);
  };

  return (
    <Fragment>
      <PageHeader periodSlug={periodSlug} />

      <ContentContainer>
        <div className="flex flew-row justify-between">
          <div style={{ width: 157 }}>
            <TrainingPeriodPicker
              renderingStyle="popover"
              currentPeriodSlug={periodSlug}
              onChange={(period: TrainingPeriod) =>
                navigate(pathToTrainingPeriodRequests(period.slug))
              }
            />
          </div>
          <div>
            <Button
              color="secondary"
              onClick={handleExport}
              additionalClassName="mr-2"
            >
              <Icon name="file_download" />
              <span>{__('Export all requests')}</span>
            </Button>
            <Button
              color="primary"
              onClick={() => setCreateModalIsActive(true)}
            >
              <Icon name="add" />
              <span>{__('Make a request')}</span>
            </Button>
          </div>
        </div>

        <RequestStats currentPeriodSlug={periodSlug} />

        <RequestList
          currentPeriodSlug={periodSlug}
          paginationType="url"
          onAfterAction={refreshStats}
          defaultPaginationParams={{
            filter: { waiting_for_approval: true },
          }}
        />

        {createModalIsActive && (
          <TrainingRequestModal
            isActive
            currentTrainingPeriodSlug={periodSlug}
            onClose={() => setCreateModalIsActive(false)}
            onAfterSubmit={() => window.location.reload()}
          />
        )}

        {exportModalIsActive && (
          <ExportModal isActive onClose={() => setExportModalIsActive(false)} />
        )}
      </ContentContainer>
    </Fragment>
  );
}

export default PeriodRequests;
